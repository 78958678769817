<template>
    <div class="terms-conditions ptb-100">
        <div class="container">
            <div class="single-privacy">
              <h3>Datenschutz</h3>
              Wir informieren Sie nachfolgend gemäß den gesetzlichen Vorgaben des Datenschutzrechts (insb. gemäß BDSG n.F. und der europäischen Datenschutz-Grundverordnung ‚DS-GVO‘) über die Art, den Umfang und Zweck der Verarbeitung personenbezogener Daten durch unser Unternehmen. Diese Datenschutzerklärung gilt auch für unsere Websites und Sozial-Media-Profile. Bezüglich der Definition von Begriffen wie etwa „personenbezogene Daten“ oder „Verarbeitung“ verweisen wir auf Art. 4 DS-GVO.
              <br><br>
              CFTools Software GmbH<br>
              Kiem-Pauli-Straße 8<br>
              84036 Landshut<br>
              Geschäftsführer Philipp Joos<br>
              E-Mail-Adresse: info@cftools.software<br>
              <br>
              Datenarten, Zwecke der Verarbeitung und Kategorien betroffener Personen<br>

              Nachfolgend informieren wir Sie über Art, Umfang und Zweck der Erhebung, Verarbeitung und Nutzung personenbezogener Daten.<br>
              <br>
              <b>1. Arten der Daten, die wir verarbeiten</b>
              <br>
              Nutzungsdaten (Zugriffszeiten, besuchte Websites etc.), Bestandsdaten (Name, Adresse etc.), Kontaktdaten (Telefonnummer, E-Mail, Fax etc.), Zahlungsdaten (Bankdaten, Kontodaten, Zahlungshistorie etc.), Vertragsdaten (Gegenstand des Vertrages, Laufzeit etc.), Inhaltsdaten (Texteingaben, Videos, Fotos etc.), Kommunikationsdaten (IP-Adresse etc.).
              <br><br>
              <b>2. Zwecke der Verarbeitung nach Art. 13 Abs. 1 c) DS-GVO</b>
              <br>
              Abwicklung von Verträgen, Beweiszwecke / Beweissicherung, Website technisch und wirtschaftlich optimieren, Leichten Zugang zur Website ermöglichen, Erfüllung vertraglicher Verpflichtungen, Kontaktaufnahme bei juristischer Beanstandung durch Dritte, Erfüllung gesetzlicher Aufbewahrungspflichten, Optimierung und statistische Auswertung unserer Dienste, Kommerzielle Nutzung der Website unterstützen, Nutzererfahrung verbessern, Website benutzerfreundlich gestalten, Wirtschaftlicher Betrieb der Werbung und Website, Marketing / Vertrieb / Werbung, Erstellung von Statistiken, Kopierwahrscheinlichkeit von Texten ermitteln, Vermeidung von SPAM und Missbrauch, Abwicklung eines Bewerberverfahrens, Kundenservice und Kundenpflege, Kontaktanfragen abwickeln, Websites mit Funktionen und Inhalten bereitstellen, Maßnahmen der Sicherheit, Unterbrechungsfreier,sicherer Betrieb unserer Website.
              <br><br>
              <b>3. Kategorien der betroffenen Personen nach Art. 13 Abs. 1 e) DS-GVO</b>
              <br>
              Besucher/Nutzer der Website, Kunden, Lieferanten, Interessenten, Bewerber, Beschäftigte, Beschäftigte von Kunden oder Lieferanten. Die betroffenen Personen werden zusammenfassend als „Nutzer“ bezeichnet.
              <br><br>
              <b>Rechtsgrundlagen der Verarbeitung personenbezogener Daten</b>
              <br><br>
              <b>Nachfolgend Informieren wir Sie über die Rechtsgrundlagen der Verarbeitung personenbezogener Daten:</b>
              <br>
              Wenn wir Ihre Einwilligung für die Verarbeitung personenbezogenen Daten eingeholt haben, ist Art. 6 Abs. 1 S. 1 lit. a) DS-GVO Rechtsgrundlage.
              Ist die Verarbeitung zur Erfüllung eines Vertrags oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, die auf Ihre Anfrage hin erfolgen, so ist Art. 6 Abs. 1 S. 1 lit. b) DS-GVO Rechtsgrundlage.
              Ist die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich, der wir unterliegen (z.B. gesetzliche Aufbewahrungspflichten), so ist Art. 6 Abs. 1 S. 1 lit. c) DS-GVO Rechtsgrundlage.
              Ist die Verarbeitung erforderlich, um lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person zu schützen, so ist Art. 6 Abs. 1 S. 1 lit. d) DS-GVO Rechtsgrundlage.
              Ist die Verarbeitung zur Wahrung unserer oder der berechtigten Interessen eines Dritten erforderlich und überwiegen diesbezüglich Ihre Interessen oder Grundrechte und Grundfreiheiten nicht, so ist Art. 6 Abs. 1 S. 1 lit. f) DS-GVO Rechtsgrundlage.
              Weitergabe personenbezogener Daten an Dritte und Auftragsverarbeiter
              <br>
              Ohne Ihre Einwilligung geben wir grundsätzlich keine Daten an Dritte weiter. Sollte dies doch der Fall sein, dann erfolgt die Weitergabe auf der Grundlage der zuvor genannten Rechtsgrundlagen z.B. bei der Weitergabe von Daten an Online-Paymentanbieter zur Vertragserfüllung oder aufgrund gerichtlicher Anordnung oder wegen einer gesetzlichen Verpflichtung zur Herausgabe der Daten zum Zwecke der Strafverfolgung, zur Gefahrenabwehr oder zur Durchsetzung der Rechte am geistigen Eigentum.
              Wir setzen zudem Auftragsverarbeiter (externe Dienstleister z.B. zum Webhosting unserer Websites und Datenbanken) zur Verarbeitung Ihrer Daten ein. Wenn im Rahmen einer Vereinbarung zur Auftragsverarbeitung an die Auftragsverarbeiter Daten weitergegeben werden, erfolgt dies immer nach Art. 28 DS-GVO. Wir wählen dabei unsere Auftragsverarbeiter sorgfältig aus, kontrollieren diese regelmäßig und haben uns ein Weisungsrecht hinsichtlich der Daten einräumen lassen. Zudem müssen die Auftragsverarbeiter geeignete technische und organisatorische Maßnahmen getroffen haben und die Datenschutzvorschriften gem. BDSG n.F. und DS-GVO einhalten
              <br><br>
              <b>Datenübermittlung in Drittstaaten</b>
              <br>
              Durch die Verabschiedung der europäischen Datenschutz-Grundverordnung (DS-GVO) wurde eine einheitliche Grundlage für den Datenschutz in Europa geschaffen. Ihre Daten werden daher vorwiegend durch Unternehmen verarbeitet, für die DS-GVO Anwendung findet. Sollte doch die Verarbeitung durch Dienste Dritter außerhalb der Europäischen Union oder des Europäischen Wirtschaftsraums stattfinden, so müssen diese die besonderen Voraussetzungen der Art. 44 ff. DS-GVO erfüllen. Das bedeutet, die Verarbeitung erfolgt aufgrund besonderer Garantien, wie etwa die von der EU-Kommission offiziell anerkannte Feststellung eines der EU entsprechenden Datenschutzniveaus oder der Beachtung offiziell anerkannter spezieller vertraglicher Verpflichtungen, der so genannten „Standardvertragsklauseln“. Bei US-Unternehmen erfüllt die Unterwerfung unter das sog. „Privacy-Shield“, dem Datenschutzabkommen zwischen der EU und den USA, diese Voraussetzungen.
              <br><br>
              <b>Löschung von Daten und Speicherdauer</b>
              <br>
              Sofern nicht in dieser Datenschutzerklärung ausdrücklich angegeben, werden Ihre personenbezogen Daten gelöscht oder gesperrt, sobald der Zweck für die Speicherung entfällt, es sei denn deren weitere Aufbewahrung ist zu Beweiszwecken erforderlich oder dem stehen gesetzliche Aufbewahrungspflichten entgegenstehen. Darunter fallen etwa handelsrechtliche Aufbewahrungspflichten von Geschäftsbriefen nach § 257 Abs. 1 HGB (6 Jahre) sowie steuerrechtliche Aufbewahrungspflichten nach § 147 Abs. 1 AO von Belegen (10 Jahre). Wenn die vorgeschriebene Aufbewahrungsfrist abläuft, erfolgt eine Sperrung oder Löschung Ihrer Daten, es sei denn die Speicherung ist weiterhin für einen Vertragsabschluss oder zur Vertragserfüllung erforderlich.
              <br><br>
              <b>Bestehen einer automatisierten Entscheidungsfindung</b>
              <br>
              Wir setzen keine automatische Entscheidungsfindung oder ein Profiling ein.
              Bereitstellung unserer Website und Erstellung von Logfiles
              <br>
              Wenn Sie unsere Webseite lediglich informatorisch nutzen (also keine Registrierung und auch keine anderweitige Übermittlung von Informationen), erheben wir nur die personenbezogenen Daten, die Ihr Browser an unseren Server übermittelt. Wenn Sie unsere Website betrachten möchten, erheben wir die folgenden Daten: • IP-Adresse;
              • Internet-Service-Provider des Nutzers;
              • Datum und Uhrzeit des Abrufs;
              • Browsertyp;
              • Sprache und Browser-Version;
              • Inhalt des Abrufs;
              • Zeitzone;
              • Zugriffsstatus/HTTP-Statuscode;
              • Datenmenge;
              • Websites, von denen die Anforderung kommt;
              • Betriebssystem.
              Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten von Ihnen findet nicht statt.
              <br><br>
              Diese Daten dienen dem Zweck der nutzerfreundlichen, funktionsfähigen und sicheren Auslieferung unserer Website an Sie mit Funktionen und Inhalten sowie deren Optimierung und statistischen Auswertung.
              <br><br>
              Rechtsgrundlage hierfür ist unser in den obigen Zwecken auch liegendes berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 S.1 lit. f) DS-GVO.
              <br><br>
              Wir speichern aus Sicherheitsgründen diese Daten in Server-Logfiles für die Speicherdauer von 70 Tagen. Nach Ablauf dieser Frist werden diese automatisch gelöscht, es sei denn wir benötigen deren Aufbewahrung zu Beweiszwecken bei Angriffen auf die Serverinfrastruktur oder anderen Rechtsverletzungen.
              Cookies
              <br><br>
              Wir verwenden sog. Cookies bei Ihrem Besuch unserer Website. Cookies sind kleine Textdateien, die Ihr Internet-Browser auf Ihrem Rechner ablegt und speichert. Wenn Sie unsere Website erneut aufrufen, geben diese Cookies Informationen ab, um Sie automatisch wiederzuerkennen. Die so erlangten Informationen dienen dem Zweck, unsere Webangebote technisch und wirtschaftlich zu optimieren und Ihnen einen leichteren und sicheren Zugang auf unsere Website zu ermöglichen. Wir informieren Sie dazu beim Aufruf unserer Website mittels eines Hinweises auf unsere Datenschutzerklärung über die Verwendung von Cookies zu den zuvor genannten Zwecken und wie Sie dieser widersprechen bzw. deren Speicherung verhindern können („Opt-out“). Unsere Website nutzt Session-Cookies, persistente Cookies und Cookies von Drittanbietern:
              <br><br>
              • Session-Cookies: Wir verwenden sog. Cookies zum Wiedererkennen mehrfacher Nutzung eines Angebots durch denselben Nutzer (z.B. wenn Sie sich eingeloggt haben zur Feststellung Ihres Login-Status). Wenn Sie unsere Seite erneut aufrufen, geben diese Cookies Informationen ab, um Sie automatisch wiederzuerkennen. Die so erlangten Informationen dienen dazu, unsere Angebote zu optimieren und Ihnen einen leichteren Zugang auf unsere Seite zu ermöglichen. Wenn Sie den Browser schließen oder Sie sich ausloggen, werden die Session-Cookies gelöscht.
              <br><br>
              • Persistente Cookies: Diese werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden kann. In den Sicherheitseinstellungen Ihres Browsers können Sie die Cookies jederzeit löschen.
              <br><br>
              • Cookies von Drittanbietern (Third-Party-Cookies): Entsprechend Ihren Wünschen können Sie können Ihre Browser-Einstellung konfigurieren und z. B. Die Annahme von Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie jedoch an dieser Stelle darauf hin, dass Sie dann eventuell nicht alle Funktionen dieser Website nutzen können. Lesen Sie Näheres zu diesen Cookies bei den jeweiligen Datenschutzerklärungen zu den Drittanbietern.
              <br><br>
              Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 S. lit. b) DS-GVO, wenn die Cookies zur Vertragsanbahnung z.B. bei Bestellungen gesetzt werden und ansonsten haben wir ein berechtigtes Interesse an der effektiven Funktionalität der Website, so dass in dem Falle Art. 6 Abs. 1 S. 1 lit. f) DS-GVO Rechtsgrundlage ist.
              <br><br>
              Widerspruch und „Opt-Out“: Das Speichern von Cookies auf Ihrer Festplatte können Sie allgemein verhindern, indem Sie in Ihren Browser-Einstellungen „keine Cookies akzeptieren“ wählen. Dies kann aber eine Funktionseinschränkung unserer Angebote zur Folge haben. Sie können dem Einsatz von Cookies von Drittanbietern zu Werbezwecken über ein sog. „Opt-out“ über diese amerikanische Website (https://optout.aboutads.info) oder diese europäische Website (http://www.youronlinechoices.com/de/praferenzmanagement/) widersprechen.
              <br><br>
              <b>Abwicklung von Verträgen</b>
              <br>
              Wir verarbeiten Bestandsdaten (z.B. Unternehmen, Titel/akademischer Grad, Namen und Adressen sowie Kontaktdaten von Nutzern, E-Mail), Vertragsdaten (z.B. in Anspruch genommene Leistungen, Namen von Kontaktpersonen) und Zahlungsdaten (z.B. Bankverbindung, Zahlungshistorie) zwecks Erfüllung unserer vertraglichen Verpflichtungen (Kenntnis, wer Vertragspartner ist; Begründung, inhaltliche Ausgestaltung und Abwicklung des Vertrags; Überprüfung auf Plausibilität der Daten) und Serviceleistungen (z.B. Kontaktaufnahme des Kundenservice) gem. Art. 6 Abs. 1 S. 1 lit b) DS-GVO. Die in Onlineformularen als verpflichtend gekennzeichneten Eingaben, sind für den Vertragsschluss erforderlich.
              <br><br>
              Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, außer sie ist zur Verfolgung unserer Ansprüche (z.B. Übergabe an Rechtsanwalt zum Inkasso) oder zur Erfüllung des Vertrags (z.B. Übergabe der Daten an Zahlungsanbieter) erforderlich oder es besteht hierzu besteht eine gesetzliche Verpflichtung gem. Art. 6 Abs. 1 S. 1 lit. c) DS-GVO.
              <br><br>
              Wir können die von Ihnen angegebenen Daten zudem verarbeiten, um Sie über weitere interessante Produkte aus unserem Portfolio zu informieren oder Ihnen E-Mails mit technischen Informationen zukommen lassen.
              <br><br>
              Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Dies ist für die Bestands- und Vertragsdaten dann der Fall, wenn die Daten für die Durchführung des Vertrages nicht mehr erforderlich sind und keine Ansprüche mehr aus dem Vertrag geltend gemacht werden können, weil diese verjährt sind (Gewährleistung: zwei Jahre / Regelverjährung: drei Jahre). Wir sind aufgrund handels- und steuerrechtlicher Vorgaben verpflichtet, Ihre Adress-, Zahlungs- und Bestelldaten für die Dauer von zehn Jahren zu speichern. Allerdings nehmen wir bei Vertragsbeendigung nach drei Jahren eine Einschränkung der Verarbeitung vor, d. h. Ihre Daten werden nur zur Einhaltung der gesetzlichen Verpflichtungen eingesetzt. Angaben im Nutzerkonto verbleiben bis zu dessen Löschung.
              <br><br>
              <b>Online Payment-Anbieter</b>
              <br>
              Die Abrechnung erfolgt bei Bezahlung per „Paypal“ über PayPal (Europe) S.àr.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg, Web: paypal.de, https://www.paypal.com/de/webapps/mpp/ua/privacy-full.
              Die Abrechnung erfolgt bei Bezahlung über „Sofort.com“ über Klarna GmbH, Theresienhöhe 12, 80339 München,  https://www.klarna.com/sofort/datenschutz/.
              Nachfolgend „Online-Abrechner“ genannt. Die Online-Abrechner erheben, speichern und verarbeiten die Nutzungs- und Abrechnungsdaten von Ihnen zur Ermittlung und zur Abrechnung der von Ihnen in Anspruch genommenen Leistung. Die bei den Online-Abrechnern eingegebenen Daten werden nur durch diese verarbeitet und bei diesen gespeichert. Sofern die Online-Abrechner die Nutzungsentgelte nicht oder nur teilweise einziehen können oder die Online-Abrechner dieses aufgrund einer Beanstandung von Ihnen unterlassen, werden die Nutzungsdaten von den Online-Abrechner an den Verantwortlichen weitergegeben und es erfolgt ggf. eine Sperrung durch den Verantwortlichen. Gleiches gilt auch, wenn z.B. eine Kreditkartengesellschaft eine Transaktion von Ihnen zu Lasten des Verantwortlichen rückabwickelt.
              <br><br>
              Rechtsgrundlage ist Art. 6 Abs. S. 1 lit. b) DSGVO, da die Verarbeitung zur Erfüllung eines Vertrags durch den Verantwortlichen erforderlich ist. Zudem werden externe Online-Abrechner auf der Grundlage von Art. 6 Abs. 1 S. 1 lit. f) DSGVO aus berechtigten Interessen des Verantwortlichen eingesetzt, um Ihnen möglichst sichere, einfache und vielfältige Zahlungsmöglichkeiten anbieten zu können.
              <br><br>
              Hinsichtlich der Speicherdauer, Widerrufs-, Auskunfts- und Betroffenenrechte verweisen wir auf die obigen Datenschutzerklärungen der Online-Abrechner.
              <br><br>
              <b>Kontaktaufnahme per Kontaktformular / E-Mail / Fax / Post</b>
              <br>
              Bei der Kontaktaufnahme mit uns per Kontaktformular, Fax, Post oder E-Mail werden Ihre Angaben zum Zwecke der Abwicklung der Kontaktanfrage verarbeitet.
              <br><br>
              Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung von Ihnen Art. 6 Abs. 1 S. 1 lit. a) DS-GVO. Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer Kontaktanfrage oder E-Mail, eines Briefes oder Faxes übermittelt werden, ist Art. 6 Abs. 1 S. 1 lit. f) DS-GVO. Der Verantwortliche hat ein berechtigtes Interesse an der Verarbeitung und Speicherung der Daten, um Anfragen der Nutzer beantworten zu können, zur Beweissicherung aus Haftungsgründen und um ggf. seiner gesetzlichen Aufbewahrungspflichten bei Geschäftsbriefen nachkommen zu können. Zielt der Kontakt auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 S. 1 lit. b) DS-GVO.
              <br><br>
              Wir können Ihre Angaben und Kontaktanfrage in unserem Customer-Relationship-Management System („CRM System“) oder einem vergleichbaren System speichern.
              <br><br>
              Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Für die personenbezogenen Daten aus der Eingabemaske des Kontaktformulars und diejenigen, die per E-Mail übersandt wurden, ist dies dann der Fall, wenn die jeweilige Konversation mit Ihnen beendet ist. Beendet ist die Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist. Anfragen von Nutzern, die über einen Account bzw. Vertrag mit uns verfügen, speichern wir bis zum Ablauf von zwei Jahren nach Vertragsbeendigung. Im Fall von gesetzlichen Archivierungspflichten erfolgt die Löschung nach deren Ablauf: Ende handelsrechtlicher (6 Jahre) und steuerrechtlicher (10 Jahre) Aufbewahrungspflicht.
              <br><br>
              Sie haben jederzeit die Möglichkeit, die Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a) DS-GVO zur Verarbeitung der personenbezogenen Daten zu widerrufen. Nehmen Sie per E-Mail Kontakt mit uns auf, so können Sie der Speicherung der personenbezogenen Daten jederzeit widersprechen.
              <br><br>
              Für die technische Reallisierung unserer E-Mail Anfragen verwenden wir <a href="https://www.mailgun.com/privacy-policy/">Mailgun</a>. Die Datenschutzerklärung finden sie <a href="https://www.mailgun.com/privacy-policy/">hier</a>.
              <br><br>
              <b>Kontaktaufnahme per Telefon</b>
              <br>
              Bei der Kontaktaufnahme mit uns per Telefon wird Ihre Telefonnummer zur Bearbeitung der Kontaktanfrage und deren Abwicklung verarbeitet und temporär im RAM / Cache des Telefongerätes / Displays gespeichert bzw. angezeigt. Die Speicherung erfolgt aus Haftungs- und Sicherheitsgründen, um den Beweis des Anrufs führen zu können sowie aus wirtschaftlichen Gründen, um einen Rückruf zu ermöglichen. Im Falle von unberechtigten Werbeanrufen, sperren wir die Rufnummern.
              <br><br>
              Rechtsgrundlage für die Verarbeitung der Telefonnummer ist Art. 6 Abs. 1 S. 1 lit. f) DS-GVO. Zielt der Kontakt auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b) DS-GVO.
              <br><br>
              Der Gerätecache speichert die Anrufe 30 Tage und überschreibt bzw. löscht sukzessiv alte Daten, bei Entsorgung des Gerätes werden alle Daten gelöscht und der Speicher ggf. zerstört. Gesperrte Telefonnummer werden jährlich auf die Notwendigkeit der Sperrung geprüft.
              <br><br>
              Sie können die Anzeige der Telefonnummer verhindern, indem Sie mit unterdrückter Telefonnummer anrufen.
              Newsletter
              <br><br>
              Unseren Newsletter können Sie mit Ihrer freiwilligen Einwilligung durch Eintragung Ihrer E-Mail-Adresse abonnieren. Nur diese ist Pflicht. Die Angabe weiterer Daten ist freiwillig und dient nur dem Zweck einer persönlichen Ansprache. Wir verwenden dabei zur Anmeldung das sog. „Double-Opt-in-Verfahren“. Nach Ihrer Anmeldung mit Ihrer E-Mail erhalten Sie von uns zur Bestätigung Ihrer Anmeldung eine E-Mail mit einem Link zur Bestätigung. Wenn Sie diesen Bestätigungslink klicken, wird Ihre E-Mail in den Newsletterverteiler aufgenommen und zum Zweck der Übersendung von E-Mails gespeichert. Sollten Sie den Klick auf den Bestätigungslink nicht innerhalb von 24 Stunden durchführen, werden Ihre Anmeldedaten gesperrt und nach 30 Tagen automatisch gelöscht.
              <br><br>
              Zudem protokollieren wir Ihre bei der Anmeldung verwendete IP-Adresse sowie das Datum und die Uhrzeit des Double-Opt-ins (Anmeldung und Bestätigung). Zweck dieser Speicherung ist Erfüllung von rechtlichen Anforderungen hinsichtlich des Nachweises Ihrer Anmeldung sowie die Missbrauchsprävention hinsichtlich Ihrer E-Mail.
              <br><br>
              Im Rahmen Ihrer Einwilligungserklärung werden die Inhalte (z.B. beworbene Produkte/Dienstleistungen, Angebote, Werbung und Themen) des Newsletters konkret beschrieben.
              <br><br>
              Wir nutzen zum E-Mail-Versand folgenden Versanddienstleister:
              Mailchimp (The Rocket Science Group LLC, a company headquartered in the State of Georgia in the United States), dessen Datenschutzerklärung finden Sie hier https://mailchimp.com/legal/privacy/. Wir haben mit dem Versanddienstleister eine Vereinbarung zur Auftragsverarbeitung nach Art. 28 DS-GVO abgeschlossen.
              <br><br>
              Beim Versand des Newsletters werten wir Ihr Nutzerverhalten aus. Die Newsletter beinhalten dafür sogenannte „Web-Beacons“ bzw. „Tracking-Pixel“, die beim Öffnen des Newsletters aufgerufen werden. Für die Auswertungen verknüpfen wir die Web-Beacons mit Ihrer E-Mail-Adresse und einer individuellen ID. Auch im Newsletter erhaltene Links enthalten diese ID. Die Daten werden ausschließlich pseudonymisiert erhoben, die IDs werden also nicht mit Ihren weiteren persönlichen Daten verknüpft, eine direkte Personenbeziehbarkeit wird ausgeschlossen. Mit diesen Daten können wir feststellen, ob und wann Sie den Newsletter geöffnet haben und welche Links im Newsletter geklickt worden sind. Dies dient dem Zweck der Optimierung und statistischen Auswertung unseres Newsletters.
              <br><br>
              Wir erstellen mit den obigen gewonnenen Daten ein Nutzerprofil, um die Lesegewohnheiten und Interessen unserer Nutzer zu identifizieren und den Newsletter damit zu individualisieren. Wenn Sie zudem auf unserer Website weitere Handlungen vorgenommen haben, verknüpfen wir diese Daten damit ebenfalls, um unsere Newsletterinhalte auf Ihre Interessen hin anzupassen.
              <br><br>
              Rechtsgrundlage für den Newsletterversand, Erfolgsmessung und die Speicherung der E-Mail ist Ihre Einwilligung gem. Art. 6 Abs. 1 S. 1 lit. a) DS-GVO i.V.m § 7 Abs. 2 Nr. 3 UWG und für die Protokollierung der Einwilligung Art. 6 Abs. 1 S. 1 lit. f) DS-GVO, da diese unserem berechtigten Interesse der juristischen Beweisbarkeit dient.
              <br><br>
              Dem Tracking können Sie jederzeit widersprechen, indem Sie den Abmeldelink am Ende des Newsletters klicken. In dem Falle würde allerdings auch der Newsletterempfang beendet. Wenn Sie in Ihrer E-Mail-Software die Anzeige von Bildern deaktivieren, ist ein Tracking ebenfalls nicht möglich. Dies kann allerdings Einschränkungen hinsichtlich der Funktionen des Newsletters haben und enthaltene Bilder werden dann nicht angezeigt.
              <br><br>
              Sie können Ihre Einwilligung in die Übersendung des Newsletters jederzeit widerrufen. Sie können den Widerruf durch Betätigung des Abmeldelinks am Ende des Newsletters, eine E-Mail oder Mitteilung an unsere obigen Kontaktdaten ausüben. Wir speichern Ihre Daten, solange Sie den Newsletter abonniert haben. Nach der Abmeldung werden Ihre Daten nur noch anonym zu statistischen Zwecken gespeichert.
              <br><br>
              <b>Google Adsense</b>
              <br>
              Wir haben Werbeanzeigen des Google Dienstes „Adsense“ (Google Ireland Limited, Registernr.: 368047, Gordon House, Barrow Street, Dublin 4, Irland) auf unserer Webseite integriert. Die Werbeanzeigen sind über den (i)-Hinweis „Google-Anzeigen“ in jeder Anzeige gekennzeichnet. Wir haben dabei die personalisierten Anzeigen aktiviert, um Ihnen interessantere Werbung anzuzeigen, die die kommerzielle Nutzung unserer Website unterstützt, den Wert für uns steigert und für Sie die Nutzererfahrung verbessert. Mithilfe personalisierter Werbung können wir über Adsense Nutzer auf Grundlage ihrer Interessen und demografischen Merkmale (z.B. „Sportbegeisterte“) erreichen.
              Zu diesen Zwecken erhält Google beim Besuch unserer Website die Information, dass Sie unsere Website aufgerufen haben. Dazu setzt Google einen Web-Beacon bzw. Cookie auf Ihren Computer. Der volle Umfang der Datenverarbeitung und die Speicherdauer ist uns nicht bekannt. Die Daten werden auch in die USA übertragen und dort analysiert. Wenn Sie mit einem Google-Account eingeloggt sind, können durch Adsense die Daten Ihrem Account zugeordnet werden. Wenn Sie dies nicht wünschen, müssen Sie sich vor dem Besuch unserer Website ausloggen.
              Aber auch andere Informationen können hierfür durch Google herangezogen werden:<br>
              <br>

              • die Art der von Ihnen besuchten Websites sowie der auf Ihrem Gerät installierten mobilen Apps;<br>

              • Cookies in Ihrem Browser und Einstellungen in Ihrem Google-Konto;<br>

              • Websites und Apps, die Sie besucht haben;<br>

              • Ihre Aktivitäten auf anderen Geräten;<br>

              • vorherige Interaktionen mit Anzeigen oder Werbediensten von Google;<br>

              • Ihre Google-Kontoaktivitäten und -informationen.<br><br>

              Bei einem Klick auf eine Adsense-Anzeige wird die IP der Nutzer durch Google verarbeitet (Nutzungsdaten), wobei die Verarbeitung pseudonymisiert (sog. „Werbe-ID“) erfolgt, indem die IP um die letzten beiden Stellen gekürzt wird.
              Google verknüpft bei personalisierter Werbung Kennungen aus Cookies oder ähnlichen Technologien nicht mit besonderen Kategorien personenbezogener Daten nach Art. 9 DS-GVO wie ethnischer Herkunft, Religion, sexueller Orientierung oder Gesundheit.
              Es ist nicht auszuschließen, dass die obigen Daten an Dritte, Behörden oder Google Partner weitergegeben werden. Diese Website hat auch Google AdSense-Anzeigen von Drittanbietern aktiviert. Die vorgenannten Daten können an diese Drittanbieter (benannt unter https://support.google.com/dfp_sb/answer/94149) übertragen werden.
              Rechtsgrundlage für die Verarbeitung Ihrer Daten ist Art. 6 Abs. 1 S. 1 lit. f) DS-GVO. Google ist nach dem EU-US Privacy Shield zertifiziert: https://www.privacyshield.gov/EU-US-Framework.<br>
              Sie können der Installation von Cookies durch Google Adsense auf verschiedene Arten widersprechen bzw. diese verhindern:
              • Sie können die Cookies in Ihrem Browser durch die Einstellung “keine Cookies akzeptieren”unterbinden, was auch die Cookies von Drittanbietern beinhaltet;
              <br><br>
              • Sie können direkt bei Google über den Link https://adssettings.google.com die personenbezogenen Anzeigen bei Google deaktivieren, wobei diese Einstellung nur solange Bestand hat bis Sie Ihre Cookies löschen. Zur Deaktivierung der personalisierten Werbung auf Mobilgeräten finden Sie hier eine Anleitung: https://support.google.com/adsense/troubleshooter/1631343;<br>

              • Sie können die personalisierten Anzeigen der Drittanbieter, die an der Werbeselbstregulierungsinitiaive “About Ads” teilnehmen über den Link https://optout.aboutads.info für US-Seiten oder für EU-Seiten unter http://www.youronlinechoices.com/de/praferenzmanagement/ deaktivieren, wobei diese Einstellung nur solange Bestand hat, bis Sie all Ihre Cookies löschen;<br>
              • Sie können durch ein Browser-Plug-in für Chrome, Firefox oder Internet-Explorer unter dem Link https://support.google.com/ads/answer/7395996 dauerhaft Cookies deaktivieren. Diese Deaktivierung kann zur Folge haben, dass Sie nicht alle Funktionen unserer Website mehr vollumfänglich nutzen können.
              <br><br>
              In der Datenschutzerklärung für Werbung von Google unter https://policies.google.com/technologies/adsfinden Sie weitere Informationen zur Verwendung von Google Cookies in Anzeigen und deren Werbetechnologien, Speicherdauer, Anonymisierung, Standortdaten, Funktionsweise und Ihre Rechte.
              <br><br>
              <b>Google AdWords mit Conversion-Tracking</b>
              <br>
              Wir nutzen den Dienst „AdWords mit Conversion-Tracking“ (Google Ireland Limited, Registernr.: 368047, Gordon House, Barrow Street, Dublin 4, Irland), um auf Websites Dritter mittels Anzeige auf unsere Website aufmerksam zu machen. Wenn Sie auf eine Google-Anzeige von uns klicken, wird ein Cookie in Ihrem Browser gespeichert, welcher etwa 30 Tage gültig ist. Wenn Sie danach unsere Website aufrufen, können wir und auch Google anhand des Cookies auswerten, ob Sie unsere Website besucht haben und welche Seite Sie bei uns besucht haben. Hierüber erstellt Google eine Statistik. Der volle Umfang der Datenverarbeitung ist uns nicht bekannt. Die Daten werden auch in die USA übertragen und dort analysiert. Wenn Sie mit einem Google-Account eingeloggt sind, können durch AdWords die Daten Ihrem Account zugeordnet werden. Wenn Sie dies nicht wünschen, müssen Sie sich vor dem Besuch unserer Website ausloggen. Dieses Conversion-Tracking dient dem Zweck der Analyse, Optimierung und dem wirtschaftlichen Betrieb unserer Werbung und Website.
              Rechtsgrundlage für die Verarbeitung Ihrer Daten ist unser berechtigtes Interesse an der Analyse, Optimierung und dem wirtschaftlichen Betrieb unserer Werbung und Website gemäß Art. 6 Abs. 1 S. 1 lit. f) DS-GVO. Google ist nach dem EU-US Privacy Shield zertifiziert: https://www.privacyshield.gov/EU-US-Framework.
              Sie können der Installation von Cookies durch Google auf verschiedene Arten widersprechen bzw. diese verhindern:
              <br><br>
              • Sie können die Cookies in Ihrem Browser durch die Einstellung “keine Cookies akzeptieren”unterbinden, was auch die Cookies von Drittanbietern beinhaltet;<br>

              • Sie können direkt bei Google über den Link https://adssettings.google.com das Conversion-Tracking deaktivieren, wobei diese Einstellung nur solange Bestand hat, bis Sie Ihre Cookies löschen.<br>

              • Sie können die personalisierten Anzeigen der Drittanbieter, die an der Werbeselbstregulierungsinitiaive “About Ads” teilnehmen über den Link https://optout.aboutads.info für US-Seiten oder für EU-Seiten unter http://www.youronlinechoices.com/de/praferenzmanagement/ deaktivieren, wobei diese Einstellung nur solange Bestand hat, bis Sie all Ihre Cookies löschen;<br>

              • Sie können durch ein Browser-Plug-in für Chrome, Firefox oder Internet-Explorer unter dem Link https://support.google.com/ads/answer/7395996 dauerhaft Cookies deaktivieren. Diese Deaktivierung kann zur Folge haben, dass Sie nicht alle Funktionen unserer Website mehr vollumfänglich nutzen können.<br><br>
              Weitere Informationen finden Sie in der Datenschutzerklärung von Google unter https://policies.google.com/privacy?hl=de&gl=de und https://services.google.com/sitestats/de.html.
              <br><br>
              <B>Google AdWords Remarketing / „Ähnliche Zielgruppen“</B>
              <br>
              Wir nutzen die Anwendung Google AdWords Remarketing/ „Ähnliche Zielgruppen“ (Google Ireland Limited, Registernr.: 368047, Gordon House, Barrow Street, Dublin 4, Irland), um auf Websites Dritter und anderen Internetangeboten mittels Anzeige auf unsere Website aufmerksam zu machen. Mit der Remarketing oder „Ähnliche Zielgruppen“ – Funktion in AdWords können wir Sie dort erreichen, wenn Sie unsere Website bereits besucht haben und Sie jeweils mit einer passenden Botschaft per Anzeige ansprechen. Mit Remarketing können wir unsere früheren Besucher erneut auf unsere Website per Klick holen. Wenn Sie bestimmte Seiten von uns besuchen, wird ein Cookie in Ihrem Browser gespeichert, welcher 30 Tage gültig ist. Wenn Sie danach andere Websites bzw. Internetangebote aufrufen, können wir und auch Google anhand des Cookies auswerten, ob Sie unsere Website bereits besucht hatten und Ihre auch dort unsere Werbung anzeigen. Hierüber erstellt Google eine Statistik. Der volle Umfang der Datenverarbeitung ist uns nicht bekannt. Die Daten werden auch in die USA übertragen und dort analysiert. Laut Google findet eine Zusammenführung der, durch das Remarketing erhobenen, Daten mit Ihren ggf. bei Google gespeicherten personenbezogenen Daten nicht statt, sondern diese werden per Pseudonym verarbeitet. Dieses Remarketing dient dem Zweck der Analyse, Optimierung und dem wirtschaftlichen Betrieb unserer Werbung und Website.
              Rechtsgrundlage für die Verarbeitung Ihrer Daten ist unser berechtigtes Interesse an der Analyse, Optimierung und dem wirtschaftlichen Betrieb unserer Werbung und Website gemäß Art. 6 Abs. 1 S. 1 lit. f) DS-GVO. Google ist nach dem EU-US Privacy Shield zertifiziert: https://www.privacyshield.gov/EU-US-Framework.
              Sie können der Installation von Cookies durch Google auf verschiedene Arten widersprechen bzw. diese verhindern:
              <br><br>
              • Sie können die Cookies in Ihrem Browser durch die Einstellung “keine Cookies akzeptieren”unterbinden, was auch die Cookies von Drittanbietern beinhaltet;<br>

              • Sie können direkt bei Google über den Link https://adssettings.google.com die personalisierten Anzeigen deaktivieren, wobei diese Einstellung nur solange Bestand hat bis Sie Ihre Cookies löschen.<br>

              • Sie können die personalisierten Anzeigen der Drittanbieter, die an der Werbeselbstregulierungsinitiaive “About Ads” teilnehmen über den Link https://optout.aboutads.info für US-Seiten oder für EU-Seiten unter http://www.youronlinechoices.com/de/praferenzmanagement/ deaktivieren, wobei diese Einstellung nur solange Bestand hat bis Sie all Ihre Cookies löschen;<br>

              • Sie können durch ein Browser-Plug-in für Chrome, Firefox oder Internet-Explorer unter dem Link https://support.google.com/ads/answer/7395996 dauerhaft Cookies deaktivieren. Diese Deaktivierung kann zur Folge haben, dass Sie nicht alle Funktionen unserer Website mehr vollumfänglich nutzen können.<br><br>
              Weitere Informationen finden Sie in der Datenschutzerklärung von Google unter https://policies.google.com/privacy?hl=de&gl=de.
              <br><br>
              <b>Google Analytics</b>
              <br>
              Wir haben das Webseitenanalyse-Tool „Google Analytics“ (Google Ireland Limited, Registernr.: 368047, Gordon House, Barrow Street, Dublin 4, Irland) auf unserer Website integriert.
              Beim Besuch unserer Website setzt Google einen Cookie auf Ihren Computer, um die Benutzung unserer Website durch Sie analysieren zu können. Die gewonnenen Daten werden in die USA übertragen und dort gespeichert. Falls personenbezogen Daten in die USA übertragen werden sollten, bietet die Zertifizierung Googles gemäß Privacy-Shield-Abkommen (https://www.privacyshield.gov/EU-US-Framework) die Garantie dafür, dass das europäische Datenschutzrecht eingehalten wird.
              Wir haben die IP-Anonymisierung „anonymizeIP“ aktiviert, wodurch die IP-Adressen nur gekürzt weiterverarbeitet werden. Auf dieser Webseite wird Ihre IP-Adresse von Google daher innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Webseite wird Google diese Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports über die Webseitenaktivitäten zusammenzustellen und um weitere, mit der Websitenutzung und der Internetnutzung verbundene, Dienstleistungen gegenüber dem Verantwortlichen zu erbringen. Wir haben darüber hinaus die geräteübergreifende Analyse von Website-Besuchern aktiviert, die über eine sog. User-ID durchgeführt wird. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Die Nutzung von Google Analytics dient dem Zweck der Analyse, Optimierung und Verbesserung unserer Website.
              Rechtsgrundlage hierfür ist unser in den obigen Zwecken auch liegendes berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 S.1 lit. f) DS-GVO.
              Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B. User-ID) oder Werbe-IDs verknüpften Daten werden nach 14 Monaten automatisch gelöscht. Die Löschung von Daten, deren Aufbewahrungsdauer erreicht ist, erfolgt automatisch einmal im Monat.
              Weitere Informationen zu Datennutzung bei Google Analytics finden Sie hier: https://www.google.com/analytics/terms/de.html (Nutzungsbedingungen von Analytics), https://support.google.com/analytics/answer/6004245?hl=de (Hinweise zum Datenschutz bei Analytics) und Googles Datenschutzerklärung https://policies.google.com/privacy.
              Widerspruch und „Opt-Out“: Das Speichern von Cookies auf Ihrer Festplatte können Sie allgemein verhindern, indem Sie in Ihren Browser-Einstellungen „keine Cookies akzeptieren“ wählen. Dies kann aber eine Funktionseinschränkung unserer Angebote zur Folge haben. Sie können darüber hinaus die Erfassung der, durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen, Daten an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: http://tools.google.com/dlpage/gaoptout?hl=de
              Als Alternative zum obigen Browser-Plugin können Sie die Erfassung durch Google Analytics unterbinden, indem Sie [__hier bitte__den Analytics Opt-Out Link Ihrer Webseite einfügen] klicken. Durch den Klick wird ein „Opt-out“-Cookie gesetzt, das die Erfassung Ihrer Daten beim Besuch dieser Webseite zukünftig verhindert. Dieses Cookie gilt nur für unsere Webseite und Ihren aktuellen Browser und hat nur solange Bestand bis Sie Ihre Cookies löschen. In dem Falle müssten Sie das Cookie erneut setzen.
              Die geräteübergreifende Nutzeranalyse können Sie in Ihrem Google-Account unter „Meine Daten > persönliche Daten“ deaktivieren.
              <br><br>
              <b>Jetpack (früher: WordPress.com-Stats)</b>
              <br>
              Wir haben auf unserer Website den Webanalysedienst Jetpack (von Automattic Inc., 60 29 th Street #343, San Francisco, CA 94110–4929, USA; Anbieter der Tracking-Technologie ist: Quantcast Inc., 201 3 rd St, Floor 2, San Francisco, CA 94103–3153, USA) integriert, um die Nutzung unserer Website zu analysieren und zu verbessern. Für die Analyse setzt die Software Cookies auf Ihrem Computer. Die Daten werden auf Servern von Jetpack in den USA übertragen, verarbeitet und gespeichert werden. Wir haben die Erweiterung zur Kürzung Ihrer IP bei Jetpack aktiviert, wodurch eine Personenbezogenheit der Daten nicht mehr möglich. Zudem wird diese IP nicht mit anderen von uns erhobenen Daten zusammengeführt. Automattic Inc. hat sich dem Privacy-Shield unterworfen: https://www.privacyshield.gov/EU-US-Framework.
              Diese Daten werden zum Zwecke des Marketings, der Analyse und Optimierung unserer Website gesammelt und gespeichert.
              Rechtsgrundlage hierfür ist unser in den obigen Zwecken auch liegendes berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 S.1 lit. f) DS-GVO.
              Sie können der Datenerhebung und -speicherung jederzeit kostenlos mit Wirkung für die Zukunft widersprechen. Sie können der Installation von Cookies auf verschiedene Arten widersprechen bzw. diese verhindern:
              <br>
              • Sie können die Cookies in Ihrem Browser durch die Einstellung “keine Cookies akzeptieren”unterbinden, was auch die Cookies von Drittanbietern beinhaltet;<br><br>
              Weitere Informationen zur Verhinderung von Cookies finden Sie oben unter „Cookies“.
              <br><br>
              <b>VG Wort</b>
              <br>
              Wir haben auf unserer Website das „Skalierbare Zentrale Messverfahren“ (SZM) der INFOnline GmbH (Brühler Str. 9, D-53119 Bonn) für die Ermittlung statistischer Kennwerte zur Ermittlung der Kopierwahrscheinlichkeit von Texten integriert.
              Dabei werden anonyme Messwerte erhoben. Die Zugriffszahlenmessung verwendet zur Wiedererkennung von Computersystemen alternativ ein Session-Cookie oder eine Signatur, die aus verschiedenen automatisch übertragenen Informationen Ihres Browsers erstellt wird. Es werden keine personenbezogenen Daten über Cookies erfasst. IP-Adressen werden nur in anonymisierter Form verarbeitet. Das Verfahren wurde unter der Beachtung des Datenschutzes entwickelt. Einziger Zweck des Verfahrens ist es, die Kopierwahrscheinlichkeit einzelner Texte zu ermitteln. Zu keinem Zeitpunkt werden einzelne Nutzer identifiziert. Ihre Identität bleibt immer geschützt. Sie erhalten über das System keine Werbung.
              Einige unserer Seiten und Beiträge sind mit JavaScript-Aufrufen versehen, über die wir die Zugriffe an die Verwertungsgesellschaft Wort (VG Wort, Rechtsfähiger Verein kraft Verleihung, Untere Weidenstraße 5, 81543 München) melden. Wir ermöglichen damit, dass unsere Autoren an den Ausschüttungen der VG Wort partizipieren, die die gesetzliche Vergütung für die Nutzungen urheberrechtlich geschützter Werke gem. § 53 UrhG sicherstellen.
              Rechtsgrundlage hierfür ist unser in den obigen Zwecken auch liegendes berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 S.1 lit. f) DS-GVO.
              Die vollständige IP-Adresse wird von der INFOnline GmbH nicht gespeichert. Die gekürzte IP-Adresse wird maximal 60 Tage gespeichert. Die Nutzungsdaten in Verbindung mit dem eindeutigen Identifier werden maximal 6 Monate gespeichert. Die IP-Adresse wie auch die gekürzte IP-Adresse werden nicht weitergegeben.
              Wenn Sie an der Messung nicht teilnehmen möchten, können Sie unter folgendem Link widersprechen: https://optout.ioam.de.
              Um einen Ausschluss von der Messung zu garantieren, ist es technisch notwendig, einen Cookie zu setzen. Sollten Sie die Cookies in Ihrem Browser löschen, ist es notwendig, den Opt-Out-Vorgang unter dem oben genannten Link zu wiederholen.
              Weitere Informationen entnehmen Sie der Datenschutzerklärung der INFOnline GmbH hier: https://www.infonline.de/datenschutz/datenschutzerklaerung/ und der von der VG Wort hier: https://www.vgwort.de/datenschutz.html.
              <br><br>
              <B>Google ReCAPTCHA</B>
              <br>
              Wir haben auf unserer Webseite die Anti-Spam-Funktion „reCAPTCHA“ von „Google“ (Google Ireland Limited, Registernr.: 368047, Gordon House, Barrow Street, Dublin 4, Irland) integriert. Durch den Einsatz von „reCAPTCHA“ in unseren Formularen können wir feststellen, ob die Eingabe durch eine Maschine (Robot) oder einen Menschen erfolgte. Bei der Nutzung des Dienstes können Ihre IP-Adresse und ggf. weitere dafür benötigte Daten an Google-Server in den USA übertragen werden.
              Zweck der Verarbeitung dieser Daten ist die Vermeidung von Spam und Missbrauch sowie unser wirtschaftliches Interesse an der Optimierung unserer Website.
              Rechtsgrundlage hierfür ist unser in den obigen Zwecken auch liegendes berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 S.1 lit. f) DS-GVO.
              Google ist unter dem Privacy-Shield-Abkommen zertifiziert (https://www.privacyshield.gov/EU-US-Framework). So wird sichergestellt, dass das europäische Datenschutzrecht eingehalten wird.
              Weitere Informationen finden Sie zu Google ReCAPTCHA unter https://www.google.com/recaptcha/ sowie in der Datenschutzerklärung von Google unter: https://policies.google.com/privacy.
              <br><br>
              <b>Google Maps</b>
              <br>
              Wir haben auf unserer Website Karten von „Google Maps“ (Google Ireland Limited, Registernr.: 368047, Gordon House, Barrow Street, Dublin 4, Irland) integriert. Damit können wirden Standort von Adressen und eine Anfahrtsbeschreibung direkt auf unserer Website in interaktiven Karten anzeigen und Ihnen die Nutzung dieses Tools ermöglichen.
              Bei dem Abruf unserer Website, wo Google Maps integriert ist, wird eine Verbindung zu den Servern von Google in den USA aufgebaut. Hierbei können Ihre IP und Standort an Google übertragen werden. Zudem erhält Google die Information, dass Sie die entsprechende Seite aufgerufen haben. Dies erfolgt auch ohne Nutzerkonto bei Google. Sollten Sie in Ihren Google-Account eingeloggt sein, kann Google die obigen Daten Ihrem Account zuordnen. Wenn Sie dies nicht wünschen, müssen Sie sich bei Ihrem Google-Account ausloggen. Google erstellt aus solchen Daten Nutzerprofile und nutzt diese Daten zum Zwecke der Werbung, Marktforschung oder Optimierung seiner Websites.
              Rechtsgrundlage hierfür ist unser in den obigen Zwecken auch liegendes berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 S.1 lit. f) DS-GVO.
              Sie haben gegenüber Google ein Widerspruchsrecht gegen die Bildung von Nutzerprofilen. Bitte richten Sie sich deswegen direkt an Google über die unten genannte Datenschutzerklärung. Ein Opt-Out-Widerspruch hinsichtlich der Werbe-Cookies können Sie hier in Ihrem Google-Account vornehmen:
              https://adssettings.google.com/authenticated.
              In den Nutzungsbedingungen von Google Maps unter https://www.google.com/intl/de_de/help/terms_maps.html und in der Datenschutzerklärung für Werbung von Google unter https://policies.google.com/technologies/ads finden Sie weitere Informationen zur Verwendung von Google Cookies und deren Werbetechnologien, Speicherdauer, Anonymisierung, Standortdaten, Funktionsweise und Ihre Rechte. Allgemeine Datenschutzerklärung von Google: https://policies.google.com/privacy.
              Google ist nach dem EU-US Privacy Shield zertifiziert (https://www.privacyshield.gov/EU-US-Framework) und daher verpflichtet europäisches Datenschutzrecht einzuhalten.
              <br><br>
              <b>Präsenz in sozialen Medien</b>
              <br>
              Wir unterhalten in sozialen Medien Profile bzw. Fanpages, um mit den dort angeschlossenen und registrierten Nutzern zu kommunizieren und um über unsere Produkte, Angebote und Dienstleistungen zu informieren. Die US-Anbieter sind nach dem sog. Privacy-Shield zertifiziert und damit verpflichtet europäischen Datenschutz einzuhalten. Bei der Nutzung und dem Aufruf unseres Profils im jeweiligen Netzwerk durch Sie gelten die jeweiligen Datenschutzhinweise und Nutzungsbedingungen des jeweiligen Netzwerks.
              Wir verarbeiten Ihre Daten, die Sie uns über diese Netzwerke senden, um mit Ihnen zu kommunizieren und um Ihre dortigen Nachrichten zu beantworten.
              Die Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten ist unser berechtigtes Interesse an der Kommunikation mit den Nutzern und unsere Außendarstellung zwecks Werbung gemäß Art. 6 Abs. 1 S. 1 lit. f) DS-GVO. Soweit Sie dem Verantwortlichen des sozialen Netzwerks eine Einwilligung in die Verarbeitung Ihrer personenbezogenen Daten erteilt haben, ist Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. a) und Art. 7 DS-GVO.
              <br><br>
              Die Datenschutzhinweise, Auskunftsmöglichkeiten und Widerspruchmöglichkeiten (Opt-Out) der jeweiligen Netzwerke finden Sie hier:
              <br><br>
              • Facebook (Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland) Datenschutzerklärung: https://www.facebook.com/about/privacy/, Opt-Out: https://www.facebook.com/settings?tab=ads und http://www.youronlinechoices.com, Privacy Shield: https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active.<br>

              • Instagram (Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA) – Datenschutzerklärung/ Opt-Out: http://instagram.com/about/legal/privacy/.<br>

              • Twitter (Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA) – Datenschutzerklärung: https://twitter.com/de/privacy, Opt-Out: https://twitter.com/personalization, Privacy Shield: https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active.<br>

              • XING (XING AG, Dammtorstraße 29-32, 20354 Hamburg, Deutschland) – Datenschutzerklärung/ Opt-Out: https://privacy.xing.com/de/datenschutzerklaerung.<br>

              • Pinterest (Pinterest Europe Ltd., Palmerston House, 2nd Floor, Fenian Street, Dublin 2, Ireland) – Datenschutzerklärung: https://policy.pinterest.com/de/privacy-policy, Opt-Out: https://help.pinterest.com/de/articles/personalized-ads-pinterest.<br>

              • LinkedIn (LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Irland) – Datenschutzerklärung: https://www.linkedin.com/legal/privacy-policy, Cookie-Richtlinie und Opt-Out: https://www.linkedin.com/legal/cookie-policy, Privacy Shield der US-Firma LinkedIn Inc.: https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active.<br>
              <br><br>
              <b>Social-Media-Plug-ins</b>
              <br>
              Wir setzen auf unserer Webseite Social-Media-Plug-ins von sozialen Netzwerken ein. Dabei nutzen wir die sog. „Zwei-Klick-Lösung“-Shariff von c’t bzw. heise.de. Beim Abruf unserer Website werden dadurch keine personenbezogenen Daten an die Anbieter der Plug-ins übermittelt. Neben dem Logo bzw. der Marke des sozialen Netzwerks finden Sie einen Regler, mit dem Sie das Plug-in per Klick aktivieren können. Nach der Aktivierung erhält der Anbieter des sozialen Netzwerks die Information, dass Sie unsere Website aufgerufen haben und Ihre personenbezogenen Daten an den Anbieter des Plug-ins übermittelt und dort gespeichert werden. Hierbei handelt es sich um sog. Thirdparty Cookies. Bei einigen Anbietern wie Facebook und XING wird nach deren Angaben Ihre IP nach der Erhebung sofort anonymisiert.
              <br><br>
              Die über den Nutzer erhobenen Daten speichert der Plug-in-Anbieter als Nutzungsprofile. Diese werden für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website verwendet. Eine solche Auswertung erfolgt insbesondere (auch für nicht eingeloggte Nutzer) zur Darstellung von bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über die Aktivitäten des Nutzers auf unserer Website zu informieren. Dem Nutzer steht ein Widerspruchsrecht gegen die Bildung dieser Nutzerprofile zu, wobei man sich zur Ausübung dieses Rechts an den jeweiligen Plug-in-Anbieter wenden muss.
              <br><br>
              Rechtsgrundlage für die Nutzung der Plug-ins ist unser berechtigtes Interesse an der Verbesserung und Optimierung unserer Website durch die Steigerung unserer Bekanntheit mittels sozialer Netzwerke sowie die Möglichkeit der Interaktion mit Ihnen und der Nutzer untereinander über soziale Netzwerke gemäß Art. 6 Abs. 1 S.1 lit. f) DS-GVO.
              <br><br>
              Auf die erhobenen Daten und Datenverarbeitungsvorgänge haben wir keinen Einfluss. Auch haben wir keine Kenntnis vom Umfang der Datenerhebung, vom Zweck der Verarbeitung und von den Speicherfristen. Auch zur Löschung der erhobenen Daten durch den Plug-in-Anbieter liegen uns keine Informationen vor.
              <br><br>
              Wir verweisen hinsichtlich des Zwecks und Umfangs der Datenerhebung und Verarbeitung auf die jeweiligen Datenschutzerklärungen der sozialen Netzwerke. Zudem finden Sie dort auch Hinweise zu Ihren Rechten und Einstellungsmöglichkeiten zum Schutz Ihrer personenbezogenen Daten.
              <br><br>
              <b>Facebook</b>
              <br>
              Wir haben auf unserer Website Plug-ins vom sozialen Netzwerk Facebook.com (Firmensitz in der EU: Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland) im Rahmen der sog. „Zwei-Klick-Lösung“ von Shariff integriert.Diese erkennen Sie am Facebook-Logo „f“ bzw. dem Zusatz „Like“, „Gefällt mir“ oder „Share“.
              <br><br>
              Sobald Sie willentlich das Facebook-Plug-in aktivieren, wird hierbei eine Verbindung von Ihrem Browser zu den Servern von Facebook hergestellt. Dabei erhält Facebook die Information, einschließlich Ihrer IP, dass Sie unsere Website aufgerufen haben und überträgt diese Information an Server von Facebook in den USA, wo diese Information gespeichert wird. Wenn Sie bei Facebook in Ihren Account eingeloggt sind, kann Facebook diese Information Ihrem Account zuordnen. Bei Nutzung der Funktionen des Plug-ins, z.B. Betätigung des „Like“-Buttons, werden diese Informationen ebenfalls von Ihrem Browser an die Server von Facebook in den USA übertragen und dort gespeichert sowie in Ihrem Facebook-Profil und ggf. bei Ihren Freunden angezeigt.
              <br><br>
              Zweck und Umfang der Datenerhebung sowie ihre weitere Verarbeitung und Nutzung der Daten durch Facebook sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre, können Sie den Datenschutzhinweisen von Facebook entnehmen: https://www.facebook.com/about/privacy/. Datenerhebung beim „Gefällt mir“-Button: https://www.facebook.com/help/186325668085084. Ihre Einstellungen hinsichtlich der Nutzung Ihrer Profildaten zu Werbezwecken bei Facebook können Sie hier verwalten und widersprechen: https://www.facebook.com/ads/preferences/.
              <br><br>
              Wenn Sie sich bei Facebook vor dem Besuch unserer Website ausloggen und Ihre Cookies löschen, werden bei der Aktivierung des Plug-ins keine Daten über den Besuch unserer Website Ihrem Profil auf Facebook zugeordnet.
              <br><br>
              Sie können auch das Laden des Facebook Plug-Ins durch sog. „Facebook Blocker“ verhindern, welche Sie als Add-on für Ihren Browser installieren können: Facebook Blocker für Firefox, Chrome und Opera oder 1blocker für Safari, iPad und iPhone.
              <br><br>
              Facebook hat sich dem Privacy Shield unterworfen und stellt damit sicher, dass europäisches Datenschutzrecht eingehalten wird: https://www.privacyshield.gov/EU-US-Framework.
              <br><br>
              <b>Twitter</b>
              <br>
              Wir haben auf unserer Website Plug-Ins des sozialen Netzwerks Twitter.com (Twitter Inc., 1355 Market St., Suite 900, San Francisco, California 94103, USA) im Rahmen der sog. „Zwei-Klick-Lösung“ von Shariff integriert. Diese Plug-Ins erkennen Sie an dem Twitter-Logo mit weißem Vogel auf blauem Hintergrund. Eine Übersicht über Twitter-Buttons bzw. Tweets finden Sie unter: https://developer.twitter.com/en/docs/twitter-for-websites/overview.
              <br><br>
              Wenn Sie in Ihren Twitter-Account eingeloggt sind, während Sie die Twitter-Plug-ins willentlich aktivieren, kann Twitter den Anruf unserer Website Ihrem Twitter-Profil zuordnen. Welche Daten an Twitter übermittelt werden, ist uns nicht bekannt.
              <br><br>
              Wenn Sie die Datenübermittlung bei Aktivierung des Plug-ins an Twitter ausschließen möchten, dann loggen Sie sich vor dem Besuch unserer Website bei Twitter aus und löschen Ihre Cookies.
              <br><br>
              Zweck und Umfang der Datenerhebung sowie ihre weitere Verarbeitung und Nutzung der Daten durch Twitter sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre, können Sie den Datenschutzhinweisen von Twitter entnehmen: https://twitter.com/de/privacy. Widerspruch (Opt-Out): https://twitter.com/personalization.
              <br><br>
              Twitter hat sich dem Privacy Shield unterworfen und stellt damit sicher, dass europäisches Datenschutzrecht eingehalten wird: https://www.privacyshield.gov/EU-US-Framework.
              <br><br>
              <b>XING</b>
              <br>
              Wir haben auf unserer Website Plug-ins vom sozialen Netzwerk XING (XING AG, Dammtorstraße 29-32, 20354 Hamburg, Deutschland) im Rahmen der sog. „Zwei-Klick-Lösung“ von Shariff integriert. Diese erkennen Sie am Share-Button mit weißem Logo von XING und dem „X“ Symbol auf grünem Hintergrund.
              <br><br>
              Wenn Sie auf unserer Website den Share-Button von XING willentlich aktivieren, führt dies beim Aufruf der jeweiligen Internetseite dazu, dass Ihr Browser eine Verbindung mit dem Server von XING aufbaut. Dabei werden laut XING keine Daten über den Aufruf gespeichert, aus denen XING einen unmittelbaren Personenbezug herleiten könnte. XING speichert insbesondere keine IP-Adressen von Ihnen und verwendet auch keine Cookies. Beim Klick auf den Share-Button werden Sie zur Startseite von XING umgeleitet, auf der Sie dann – falls Sie eingeloggt sind – unsere Seite empfehlen können, was dem Zweck der Steigerung unserer Bekanntheit und Reichweite dient. Hinsichtlich dieser Aktivitäten auf der XING Plattform gilt die unten genannte Datenschutzerklärung von XING.
              <br><br>
              Wenn Sie sich bei XING vor dem Besuch unserer Website ausloggen und Ihre Cookies löschen, werden bei der Aktivierung des Plug-ins keine Daten über den Besuch unserer Website Ihrem Profil auf XING zugeordnet.
              <br><br>
              Zweck und Umfang der Datenerhebung sowie ihre weitere Verarbeitung und Nutzung der Daten durch XING sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre, können Sie den Datenschutzhinweisen von XING zum Share-Button entnehmen unter https://www.xing.com/app/share%3Fop%3Ddata_protection sowie der allgemeinen Datenschutzerklärung von XING unter
              https://privacy.xing.com/de/datenschutzerklaerung.
              <br><br>
              <b>Instagram</b>
              <br>
              Wir haben auf unserer Website Plug-Ins vom sozialen Netzwerk Instagram (Instagram LLC., 1601 Willow Road, Menlo Park, CA, 94025, USA) im Rahmen der sog. „Zwei-Klick-Lösung“ von Shariff integriert. Diese erkennen Sie am Instagram-Logo in der Form einer viereckigen Kamera.
              <br><br>
              Wenn Sie willentlich das Plug-in aktivieren, wird hierbei eine Verbindung von Ihrem Browser zu den Servern von Instagram hergestellt. Dabei erhält Instagram die Information, einschließlich Ihrer IP-Adresse, dass Sie unsere Seite besucht haben und überträgt die Information an Server von Instagram in den USA, wo diese Information gespeichert wird. Wenn Sie bei Instagram in Ihren Account eingeloggt sind, kann Instagram diese Information Ihrem Account zuordnen und Sie können den Instagram-Button anklicken und so die Inhalte unserer Seiten auf Ihrem Instagram-Account teilen und speichern sowie ggf. Ihren dortigen Freunden anzeigen. Wir haben keine Kenntnis über den genauen Inhalt der übermittelten Daten, deren Nutzung und Speicherdauer durch Instagram.
              <br><br>
              Wenn Sie sich bei Instagram vor dem Besuch unserer Website ausloggen und Ihre Cookies löschen, werden bei der Aktivierung des Plug-ins keine Daten über den Besuch unserer Website Ihrem Profil auf Instagram zugeordnet.
              <br><br>
              Sie erhalten weitere Informationen in der Datenschutzerklärung von Instagram unter https://help.instagram.com/519522125107875 und zu den Einstellungen zum Schutz der Privatsphäre hier: https://help.instagram.com/196883487377501.
              <br><br>
              <b>Cloudflare</b>
              <br>
              Wir nutzen für die Einbindung von Skripten und Bibliotheken auf unserer Website ein Content Delivery Network (CDN) der Cloudflare, Inc. 101 Townsend Street, San Francisco, California 94107, USA ("Cloudflare"). Bei einem Content Delivery Network wird der Inhalt unserer Website auf dem Server des Dienstes gespeichert und der Dienst verteilt diesen Inhalt über ein Netzwerk regionaler Server an Sie oder Ihren Browser, wenn die Webseite aufgerufen wird. Dies dient dazu, Angriffe, wie z.B. DDos-Attacken, auf unsere Website besser abwehren zu können und Ihnen bei großen Skript- und Datenmengen die Inhalte unserer Seiten schnell und optimiert zur Verfügung zu stellen.
              <br><br>
              Da der Datenverkehr unserer Website über die Server von Cloudfare läuft, wird auch Ihre IP-Adresse an Cloudflare übermittelt. Cloudflare ist unter dem US-Privacy-Shield zertifiziert und gewährleistet somit eine Datenverarbeitung im Sinne der DSGVO. Wir haben zudem einen Auftragsverarbeitungsvertrag (Datenverarbeitungsvertrag) mit Cloudflare abgeschlossen, mit dem dieses Unternehmen uns die Einhaltung geeigneter technischer und organisatorischer Maßnahmen nachweist.
              <br><br>
              Die Rechtsgrundlage für die Nutzung von Cloudflare durch uns ist Art. 6 I (f) DSGVO. Unser berechtigtes Interesse ergibt sich daraus, dass wir den Dienst nutzen können, um Sicherheitsmaßnahmen gegen Angriffe auf unsere Website zu treffen und große Datenmengen optimiert ausliefern zu können. Der Eingriff in Ihre Grundfreiheiten ist hier gering, da Cloudflare das Datenschutzniveau nach der DSGVO gewährleistet.
              <br><br>
              <b>Datenschutz bei Bewerbungen und im Bewerberverfahren</b>
              <br>
              Bewerbungen, die auf elektronischem Wege oder per Post an den Verantwortlichen gesendet werden, werden zum Zwecke der Abwicklung des Bewerberverfahrens elektronisch oder manuell verarbeitet.
              <br><br>
              Wir weisen ausdrücklich darauf hin, dass Bewerbungsunterlagen mit „besonderen Kategorien personenbezogener Daten“ nach Art. 9 DS-GVO (z.B. ein Foto, welches Rückschlüsse auf Ihre ethnische Herkunft, Religion oder Ihren Familienstand gibt), mit Ausnahme einer eventuellen Schwerbehinderung, welche Sie aus freier Entscheidung offenlegen möchten, unerwünscht sind. Sie sollen Ihre Bewerbung ohne diese Daten einreichen. Dies hat keine Auswirkungen auf Ihre Bewerberchancen.
              <br><br>
              Rechtsgrundlagen für die Verarbeitung sind Art. 6 Abs. 1 S.1 lit. b) DS-GVO sowie § 26 BDSG n.F.
              <br><br>
              Wird nach Abschluss des Bewerberverfahrens, ein Arbeitsverhältnis mit dem Bewerber / der Bewerberin eingegangen, werden die Bewerberdaten unter Beachtung einschlägiger Datenschutzvorschriften gespeichert. Wird Ihnen nach Abschluss des Bewerberverfahrens keine Stelle angeboten, so wird Ihr eingereichtes Bewerbungsschreiben samt Unterlagen 6 Monate nach Versand der Absage gelöscht, um etwaigen Ansprüchen und Nachweispflichten nach AGG genügen zu können.
              <br><br>
              <b>Rechte der betroffenen Person</b>
              <br>
              Widerspruch oder Widerruf gegen die Verarbeitung Ihrer Daten
              <br><br>
              Soweit die Verarbeitung auf Ihrer Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a), Art. 7 DS-GVO beruht, haben Sie das Recht, die Einwilligung jederzeit zu widerrufen. Die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung wird dadurch nicht berührt.
              <br><br>
              Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die Interessenabwägung gemäß Art. 6 Abs. 1 S. 1 lit. f) DS-GVO stützen, können Sie Widerspruch gegen die Verarbeitung einlegen. Dies ist der Fall, wenn die Verarbeitung insbesondere nicht zur Erfüllung eines Vertrags mit Ihnen erforderlich ist, was von uns jeweils bei der nachfolgenden Beschreibung der Funktionen dargestellt wird. Bei Ausübung eines solchen Widerspruchs bitten wir um Darlegung der Gründe, weshalb wir Ihre personenbezogenen Daten nicht wie von uns durchgeführt verarbeiten sollten. Im Falle Ihres begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen.
              <br><br>
              Sie können der Verarbeitung Ihrer personenbezogenen Daten für Zwecke der Werbung und Datenanalyse jederzeit widersprechen. Das Widerspruchsrecht können Sie kostenfrei ausüben. Über Ihren Werbewiderspruch können Sie uns unter folgenden Kontaktdaten informieren:
              <br><br>
              CFTools Software GmbH<br>
              Kiem-Pauli-Straße 8<br>
              84036 Landshut<br>
              Geschäftsführer Philipp Joos<br>
              E-Mail-Adresse: info@cftools.software<br>
              <br><br>
              <b>Recht auf Auskunft</b>
              <br>
              Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen, ob Sie betreffende personenbezogene Daten verarbeitet werden. Sofern dies der Fall ist, haben Sie ein Recht auf Auskunft über Ihre bei uns gespeicherten persönlichen Daten nach Art. 15 DS-GVO. Dies beinhaltet insbesondere die Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, die Herkunft ihrer Daten, sofern diese nicht direkt bei Ihnen erhoben wurden.
              <br><br>
              <b>Recht auf Berichtigung</b>
              <br>
              Sie haben ein Recht auf Berichtigung unrichtiger oder auf Vervollständigung richtiger Daten nach Art. 16 DS-GVO.
              <br><br>
              <b>Recht auf Löschung</b>
              <br>
              Sie haben ein Recht auf Löschung Ihrer bei uns gespeicherten Daten nach Art. 17 DS-GVO, es sei denn gesetzliche oder vertraglichen Aufbewahrungsfristen oder andere gesetzliche Pflichten bzw. Rechte zur weiteren Speicherung stehen dieser entgegen.
              <br><br>
              <b>Recht auf Einschränkung</b>
              <br>
              Sie haben das Recht, eine Einschränkung bei der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, wenn eine der Voraussetzungen in Art. 18 Abs. 1 lit. a) bis d) DS-GVO erfüllt ist:<br>
              • Wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;<br>

              • die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;<br>

              • der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder<br>

              • wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DS-GVO eingelegt haben und noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen überwiegen.<br>
              <br><br>
              <b>Recht auf Datenübertragbarkeit</b>
              <br>
              Sie haben ein Recht auf Datenübertragbarkeit nach Art. 20 DS-GVO, was bedeutet, dass Sie die bei uns über Sie gespeicherten personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format erhalten können oder die Übermittlung an einen anderen Verantwortlichen verlangen können.
              <br><br>
              <b>Recht auf Beschwerde</b>
              <br>
              Sie haben ein Recht auf Beschwerde bei einer Aufsichtsbehörde. In der Regel können Sie sich hierfür an die Aufsichtsbehörde insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes wenden.


              <br><br>
              <b>Datensicherheit</b>
              <br>
              Um alle personenbezogen Daten, die an uns übermittelt werden, zu schützen und um sicherzustellen, dass die Datenschutzvorschriften von uns, aber auch unseren externen Dienstleistern eingehalten werden, haben wir geeignete technische und organisatorische Sicherheitsmaßnahmen getroffen. Deshalb werden unter anderem alle Daten zwischen Ihrem Browser und unserem Server über eine sichere SSL-Verbindung verschlüsselt übertragen.
              <br>
              <br>
              <b>Stand: 01.01.2021</b>

            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'PrivacyPolicyContent'
}
</script>